import React, { ChangeEvent, Component } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import { setFullName, setMobile, setNin, setCommercialNumber, setServiceType, setToken } from '../core/actions/common';
import { connect } from 'react-redux';
import { State } from '../core/reducers';
import appconfig from './../appconfig.json';
import axios from 'axios';
import setLanguage, { translations } from 'translation';
//import { store } from "App";
import NafathModal from './NafathModal';
import Nafath from './Nafath';

export interface CommonProps {
    serviceType: string;
    fullName: string;
    nin: string;
    mobile: string;
    commercialNumber: string;
    setServiceType(serviceType: string): void;
    setFullName(fullName: string): void;
    setNin(nin: string): void;
    setCommercialNumber(commercialNumber: string): void;
    setMobile(mobile: string): void;
    setToken(token: string): void;
}

class InputForm extends Component {
    constructor(public props: CommonProps) {
        super(props);
    }

    state = {
        nin: '',
        fullname: '',
        commercialNumber: '',
        mobile: '',
        workingHour: false,
        visitors: false,
        investors: false,
        workingHourMessage: false,
        selectedIndex: '',
        nafathOpen: false,
        nafathObject: {},
        loadingCheckStatus: false,
        urlAttributes: {},
        nafathErrorApi: false,
        // new state
        showNafathBlock: false,
        investorDataFromNafath: {},
        dataI18n: 'nin_residence',
        selectedLanguage: 'Arabic'
    };
    /*  state = {
           language:"en",
           pageDirection: "ltr",
           textAlignment: "left",
       }
         */
    absherIntegrationStatus: boolean = true;
    componentDidMount(): void {
        // var videoSource = document.getElementById("videoSource") as HTMLVideoElement;
        // var videourl = require("../assets/waitingVideoSmall.mp4");
        // videoSource.setAttribute("src", videourl);
        localStorage.removeItem('userType');
        localStorage.removeItem('serviceEntity');

        /*  const languageSelector = document.getElementById("languageSelector") as HTMLInputElement;
             let language = languageSelector.value;
             
             if(language == "ar"){
                 this.setState({
                     language:"ar",
                     pageDirection: "rlt",
                     textAlignment: "right"
                 })
             }else{
                 this.setState({
                     language:"en",
                     pageDirection: "ltr",
                     textAlignment: "left"
                 })
             } */
        this.checkAbsherIntegration();
        setLanguage('ar');
    }

    checkAbsherIntegration = () => {
        this.absherIntegrationStatus = false;
        /*
           let url = appconfig.azure_reports_url + "/report/absherstatus";
            fetch(url).then(response => {
                return response.json();
            }).then(json => {
                console.log("absher statusresponse = " + json.status)
                if (json.status == "enabled") {
                    this.absherIntegrationStatus = true;
                } else {
                    this.absherIntegrationStatus = false;
                }
            }).catch(error => {
                alert("Fetch absher status error > " + error)
            }) 
    */
    };

    handleChangeLanguage = (event: ChangeEvent<HTMLInputElement>) => {
        this.setState({ selectedLanguage: event.target.value });
    };

    openWelcomeScreen = async () => {
        const languageSelector = document.getElementById('languageSelector') as HTMLInputElement;
        let language = languageSelector.value;

        let serviceType = document.querySelector('input[name=serviceType]:checked') as HTMLInputElement;
        let serviceChk = document.querySelectorAll('input[name=serviceType]:checked');
        let languageCHK = document.querySelectorAll('input[name="language"]:checked');
        let serviceLanguage = document.querySelector('input[name=language]:checked') as HTMLInputElement;
        let langvalidMSG = document.getElementById('languageValidationMsg') as HTMLInputElement;
        let nin = document.getElementById('nin') as HTMLInputElement;
        let name = document.getElementById('fullname') as HTMLInputElement;
        let mobile = document.getElementById('mobile') as HTMLInputElement;
        let email = document.getElementById('email') as HTMLInputElement;
        let entity = document.getElementById('entity') as HTMLInputElement;
        let commercial_record = document.getElementById('commercial_record') as HTMLInputElement;
        let serviceTypeValidationMsg = document.getElementById('serviceTypeValidationMsg') as HTMLElement;
        let ninRequiredMsg = document.getElementById('ninRequiredMsg') as HTMLElement;
        let nameRequiredMsg = document.getElementById('nameRequiredMsg') as HTMLElement;
        let mobileRequiredMsg = document.getElementById('mobileRequiredMsg') as HTMLElement;
        let emailRequiredMsg = document.getElementById('emailRequiredMsg') as HTMLElement;
        let securityVonfirmRequiredMsg = document.getElementById('securityVonfirmRequiredMsg') as HTMLElement;
        let entityRequiredMsg = document.getElementById('entityRequiredMsg') as HTMLElement;
        let recordConfirmRequiredMsg = document.getElementById('recordConfirmRequiredMsg') as HTMLElement;
        let crRequiredMsg = document.getElementById('crRequiredMsg') as HTMLElement;

        var numbersExp = /^[0-9a-zA-Z]+$/;
        let validInputs = true;
        let nmaeRegex = /[a-zA-Z\u0600-\u06FF]+\d*/;
        let ninRegex = /^(?=.*\d)[a-zA-Z0-9]+$/;
 

        if (nin.value == '') {
            ninRequiredMsg.innerHTML = translations[language]['required_field'];
            ninRequiredMsg.style.display = '';
            validInputs = false;
        } else if (nin.value !== '' && nin.value.length < 6) {
            ninRequiredMsg.innerHTML = translations[language]['validation_length_nin'];
            ninRequiredMsg.style.display = '';
            validInputs = false;
        } else if (!nin.value.match(ninRegex)) {// || !(nin.value.startsWith('1') || nin.value.startsWith('2'))
            //ninRequiredMsg.innerHTML = "ية غير صحيح";
            console.log(111111);
            ninRequiredMsg.innerHTML = translations[language]['validation_length_nin'];
            ninRequiredMsg.style.display = '';
            validInputs = false;
        } else {

            ninRequiredMsg.style.display = 'none';
        }


        if (languageCHK.length == 0) {
            langvalidMSG.style.display = '';
            validInputs = false;
        } else {
            langvalidMSG.style.display = 'none';
        }
        if (serviceChk.length == 0) {
            validInputs = false;
            serviceTypeValidationMsg.style.display = '';
        } else {
            serviceTypeValidationMsg.style.display = 'none';
        }

        if (name.value == '') {
            nameRequiredMsg.textContent = translations[language]['required_field'];
            nameRequiredMsg.style.display = '';
            validInputs = false;
        } else if (nmaeRegex.test(name.value)) {
            nameRequiredMsg.style.display = 'none';
        } else {
            nameRequiredMsg.style.display = '';
            nameRequiredMsg.textContent = translations[language]['validation_name'];
            validInputs = false;
        }

        if (mobile.value == '') {
            mobileRequiredMsg.innerHTML = translations[language]['required_field'];
            mobileRequiredMsg.style.display = '';
            mobile.focus();
            validInputs = false;
        } else {
            mobileRequiredMsg.style.display = 'none';
        }

        if (mobile.value != '') {
            if (!mobile.value.match(numbersExp) || mobile.value.length != 10) {
                console.log(22222222);
                //mobileRequiredMsg.innerHTML = "يجب ان يكون رقم الجوال مكون من ١٠ ارقام";
                mobileRequiredMsg.innerHTML = translations[language]['validation_invalid_mobile'];
                mobileRequiredMsg.style.display = '';
                mobile.focus();
                validInputs = false;
            } else {
                mobileRequiredMsg.style.display = 'none';
            }
        }
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Example usage
        const isValidEmail = emailRegex.test(email.value);
        console.log(isValidEmail); // Output: true
        if (email.value != '') {
            if (isValidEmail == false) {
                emailRequiredMsg.style.display = '';
                email.focus();
                validInputs = false;
            } else {
                emailRequiredMsg.style.display = 'none';
            }
        } else {
            emailRequiredMsg.style.display = 'none';
        }
        let securityConfirm = document.getElementById('securityConfirm') as HTMLInputElement;
        let recordConfirm = document.getElementById('recordConfirm') as HTMLInputElement;
        let submitbtn = document.getElementById('submitbtn') as HTMLButtonElement;
        if (securityConfirm.checked == false) {
            submitbtn.style.backgroundColor = '#006937';
            securityVonfirmRequiredMsg.style.display = '';
            validInputs = false;
        } else {
            securityVonfirmRequiredMsg.style.display = 'none';
        }
        if (recordConfirm.checked == false) {
            recordConfirmRequiredMsg.style.display = '';
            validInputs = false;
        } else {
            recordConfirmRequiredMsg.style.display = 'none';
        }

        const checkEntity = entity?.value;

        if (serviceType.value == '') {
            if (checkEntity == '0') {
                entityRequiredMsg.style.display = '';
                validInputs = false;
            } else {
                entityRequiredMsg.style.display = 'none';
            }
            if (commercial_record.value == '') {
                crRequiredMsg.innerHTML = translations[language]['required_field'];
                crRequiredMsg.style.display = '';
                validInputs = false;
            } else if (commercial_record.value.length < 10) {
                crRequiredMsg.innerHTML = translations[language]['digit10Msg'];
                crRequiredMsg.style.display = '';
                validInputs = false;
            } else {
                crRequiredMsg.style.display = 'none';
            }
        }
        if (validInputs == false) {
            return false;
        }

        this.props.setFullName(name.value);
        this.props.setNin(nin?.value);

        /* if(serviceType.value == "gosi-ppa"){
                 this.props.setNin(nin.value);
             }else{
                 
             }*/
        this.props.setMobile(mobile.value);
        this.props.setServiceType(serviceType.value);
        if (commercial_record?.value) {
            localStorage.setItem('commercialNumber', commercial_record?.value);
        }
        if (email?.value) {
            localStorage.setItem('emailAddress', email?.value);
        }
        if (serviceLanguage?.value) {
            localStorage.setItem('serviceLanguage', serviceLanguage?.value);
        }

        //alert(this.absherIntegrationStatus);
        if (this.absherIntegrationStatus && serviceType.value == 'sbc-others') {
            this.sendOtp();
        } else {
            this.props.setToken('');
            const urlParams = new URLSearchParams(window.location.search);
            const client = urlParams.get('client');
            let service = 'sbc-others';
            if (serviceType.value == 'sbc-others') {
                //this.props.setCommercialNumber(commercial_record.value);

                window.location.href =
                    '?isPublic=true&config=true&fullname=' +
                    name.value +
                    '&nin=' +
                    nin?.value +
                    '&mobile=' +
                    mobile.value +
                    '&lng=' +
                    this.state.selectedLanguage +
                    '&serviceType=' +
                    service +
                    '&client=' +
                    client;
                return;
            } else {
                let entity = document.getElementById('entity') as HTMLInputElement;
                if (entity != undefined && entity.value == 'sbc-moe') {
                    service = 'sbc-moe';
                    localStorage.setItem('serviceEntity', 'وزارة التعليم');
                } else if (entity != undefined && entity.value == 'sbc-hrsd') {
                    service = 'sbc-hrsd';
                    localStorage.setItem('serviceEntity', 'وزارة الموارد البشرية والتنمية الاجتماعية');
                } else if (entity != undefined && entity.value == 'sbc-gosi') {
                    service = 'sbc-gosi';
                    localStorage.setItem('serviceEntity', 'المؤسسة العامة للتأمينات الاجتماعية');
                } else if (entity != undefined && entity.value == 'sbc-zatca') {
                    service = 'sbc-zatca';
                    localStorage.setItem('serviceEntity', 'هيئة الزكاة والضريبة والجمارك');
                } else if (entity != undefined && entity.value == 'sbc-investment') {
                    service = 'sbc-investment';
                    localStorage.setItem('serviceEntity', 'وزارة الاستثمار');
                } else if (entity != undefined && entity.value == 'sbc-tourism') {
                    service = 'sbc-tourism';
                    localStorage.setItem('serviceEntity', 'وزارة السياحة');
                } else if (entity != undefined && entity.value == 'sbc-moc') {
                    service = 'sbc-moc';
                    localStorage.setItem('serviceEntity', 'وزارة التجارة');
                } else if (entity != undefined && entity.value == 'sbc-momrah') {
                    service = 'sbc-momrah';
                    localStorage.setItem('serviceEntity', 'وزارة البلديات والإسكان');
                } else if (entity != undefined && entity.value == 'sbc-federation') {
                    service = 'sbc-federation';
                    localStorage.setItem('serviceEntity', 'اتحاد الغرف السعودية');
                } else if (entity != undefined && entity.value == 'sbc-verification') {
                    service = 'sbc-verification';
                    localStorage.setItem('serviceEntity', 'التوثيق');
                } else if (entity != undefined && entity.value == 'sbc-compatibility') {
                    service = 'sbc-compatibility';
                    localStorage.setItem('serviceEntity', 'الموائمة');
                } else if (
                    entity != undefined &&
                    entity.value == 'sbc-others' &&
                    localStorage.getItem('userType') == 'Investor'
                ) {
                    service = 'sbc-others';
                    localStorage.setItem('serviceEntity', 'المركز السعودي للأعمال (الموظف الشامل)');
                } else {
                    localStorage.setItem('serviceEntity', entity.value);
                }


                this.props.setServiceType(service);
                // this.props.setCommercialNumber(commercial_record.value);
                //logic kiro
                console.log('nin kiro', nin.value);

                window.location.href =
                    '?isPublic=true&config=true&fullname=' +
                    name.value +
                    '&nin=' +
                    commercial_record.value +
                    '&mobile=' +
                    mobile.value +
                    '&lng=' +
                    this.state.selectedLanguage +
                    '&serviceType=' +
                    service +
                    '&investorData=' +
                    this.state.investorDataFromNafath +
                    '&client=' +
                    client;
                return;
            }
        }
        return;
    };
    handelSecurity = () => {
        let securityVonfirmRequiredMsg = document.getElementById('securityVonfirmRequiredMsg') as HTMLElement;
        securityVonfirmRequiredMsg.style.display = 'none';
    }
    handelRecord = () => {
        let recordConfirmRequiredMsg = document.getElementById('recordConfirmRequiredMsg') as HTMLElement;
        recordConfirmRequiredMsg.style.display = 'none';

    }
    handleCloseNafathModal = () => {
        this.setState({ nafathOpen: false });
    };

    sendToNafath = async (nin: any) => {
        let api_url = appconfig.azure_nafath + `/api/v1/endakNafath/sbc/login`;

        // appconfig.azure_reports_url + "/api/sendcustomerotp";
        const body = {
            id: Number(nin)
        };

        const options = {
            headers: {
                'Content-Type': 'application/json'
            }
        };
        let responseApi;

        await axios
            .post(api_url, body, options)
            .then((response) => {
                console.log('send otp response = ' + response);
                responseApi = { ...response };

            })
            .catch((err) => {
                //submitButtonSpinner.style.display = 'none';
                // submitbtn.disabled = false;
                console.log(err);
                responseApi = err;

                //alert('تعذر ارسال رمز التحقق، الرجاء المحاولة في وقت لاحق');
            });
        console.log('responseapi', responseApi);
        return responseApi;
    };

    sendOtp = () => {
        let nin = document.getElementById('nin') as HTMLInputElement;
        let name = document.getElementById('fullname') as HTMLInputElement;
        let mobile = document.getElementById('mobile') as HTMLInputElement;
        let submitbtn = document.getElementById('submitbtn') as HTMLInputElement;
        let submitButtonSpinner = document.getElementById('submitButtonSpinner') as HTMLInputElement;

        submitButtonSpinner.style.display = '';
        submitbtn.disabled = true;

        let otp_url = appconfig.azure_reports_url + '/api/sendcustomerotp';
        const body = {
            nin: nin?.value,
            mobile: mobile.value,
            fullname: name.value
        };

        const options = {
            headers: {
                'Content-Type': 'application/json'
            }
        };

        axios
            .post(otp_url, body, options)
            .then((response) => {
                console.log('send otp response = ' + response);
                //alert(JSON.stringify(response));
                if (response.data.successMessage) {
                    const urlParams = new URLSearchParams(window.location.search);
                    const client = urlParams.get('client');
                    window.location.href = 'otp?isPublic=true&config=true&client=' + client;
                } else {
                    submitButtonSpinner.style.display = 'none';
                    submitbtn.disabled = false;
                    //alert("Failed to send otp");
                    alert('تعذر ارسال رمز التحقق، الرجاء المحاولة في وقت لاحق');
                }
            })
            .catch((err) => {
                submitButtonSpinner.style.display = 'none';
                submitbtn.disabled = false;
                console.log(err);
                alert('تعذر ارسال رمز التحقق، الرجاء المحاولة في وقت لاحق');
            });
    };

    checkNinOnlyDigits = (event: any) => {
        this.setState({ nin: event.target.value });
        //  const languageSelector = document.getElementById('languageSelector') as HTMLInputElement;
        //  let language = languageSelector.value;
        let ninRequiredMsg = document.getElementById('ninRequiredMsg') as HTMLElement;
        // const re = /^[0-9\b]+$/;
        // //const re = /^[\u0660-\u0669\u06F0-\u06F90-9]/g;
        // if (event.target.value === '' || re.test(event.target.value)) {
        //     this.setState({ nin: event.target.value });
        //     ninRequiredMsg.style.display = 'none';
        // } else {
        //     ninRequiredMsg.style.display = '';
        //     ninRequiredMsg.innerHTML = translations[language]['validation_only_digits']; //" غير مسموح باستخدام الحروف";
        // }
        if (event.target.value != '') {
            ninRequiredMsg.style.display = 'none';
        }
    };

    onChangeFullName = (event: any) => {
        const languageSelector = document.getElementById('languageSelector') as HTMLInputElement;
        let language = languageSelector.value;
        let nameRequiredMsg = document.getElementById('nameRequiredMsg') as HTMLElement;
        let nameRegex = /[a-zA-Z\u0600-\u06FF]+\d*/;

        if (event.target.value === '' || nameRegex.test(event.target.value)) {
            this.setState({ fullname: event.target.value });
            nameRequiredMsg.style.display = 'none';
        } else {
            nameRequiredMsg.style.display = '';
            nameRequiredMsg.innerHTML = translations[language]['validation_name']; //" غير مسموح باستخدام الحروف";
        }
    };

    checkregistrationNumber = (event: any) => {
        const languageSelector = document.getElementById('languageSelector') as HTMLInputElement;
        let language = languageSelector.value;
        let crRequiredMsg = document.getElementById('crRequiredMsg') as HTMLElement;
        const re = /^[0-9\b]+$/;
        //const re = /^[\u0660-\u0669\u06F0-\u06F90-9]/g
        if (event.target.value === '' || re.test(event.target.value)) {
            this.setState({ commercialNumber: event.target.value });
            crRequiredMsg.style.display = 'none';
        } else {
            crRequiredMsg.style.display = '';
            crRequiredMsg.innerHTML = translations[language]['validation_only_digits']; //" غير مسموح باستخدام الحروف";
        }
    };
    checkMobileOnlyDigits = (event: any) => {
        const languageSelector = document.getElementById('languageSelector') as HTMLInputElement;
        let language = languageSelector.value;
        let mobileRequiredMsg = document.getElementById('mobileRequiredMsg') as HTMLElement;
        const re = /^[0-9\b]+$/;
        if (event.target.value === '' || re.test(event.target.value)) {
            this.setState({ mobile: event.target.value });
            mobileRequiredMsg.style.display = 'none';
        } else {
            mobileRequiredMsg.style.display = '';
            mobileRequiredMsg.innerHTML = translations[language]['validation_only_digits']; //" غير مسموح باستخدام الحروف";
        }
    };

    checkEmail = (event: any) => {
        let emailRequiredMsg = document.getElementById('emailRequiredMsg') as HTMLElement;
        if (event.target.value) {

            emailRequiredMsg.style.display = 'none';
        }
    };

    displayEntity = () => {
        console.log('Display Entity');
        let language = localStorage.getItem('lang');
        this.setState({ workingHour: false });
        this.setState({ workingHourMessage: false });
        setLanguage(language);
        localStorage.setItem('userType', 'Investor');
        this.setState({ investorDataFromNafath: {} });
        this.setState({ fullname: '' });
        this.setState({ nin: '' });
        this.setState({ investors: true });
        this.setState({ dataI18n: 'nin' });
        // let entity_row = document.getElementById('entity_row') as HTMLInputElement;
        // let nin_row_1 = document.getElementById("nin_row_1") as HTMLElement;
        // let nin_row_2 = document.getElementById("nin_row_2") as HTMLElement;
        // let nin_row_3 = document.getElementById("nin_row_3") as HTMLElement;

        //let cr_row_1 = document.getElementById('cr_row_1') as HTMLInputElement;
        // let cr_row_2 = document.getElementById('cr_row_2') as HTMLInputElement;
        // let cr_row_3 = document.getElementById('cr_row_3') as HTMLInputElement;
        // [SOLY] start show investor detaills
        // entity_row.style.display = '';
        // cr_row_1.style.display = '';
        // cr_row_2.style.display = '';
        // cr_row_3.style.display = '';
        // [SOLY] end show investor detaills
        this.setState({ showNafathBlock: true });
        // nin_row_1.style.display = 'none';
        // nin_row_2.style.display = 'none';
        // nin_row_3.style.display = 'none';
    };

    hideEntity = () => {
        this.setState({ showNafathBlock: false });
        this.setState({ selectedIndex: 0 });
        this.setState({ workingHourMessage: false });
        this.setState({ investorDataFromNafath: {} });
        this.setState({ fullname: '' });
        this.setState({ nin: '' });
        this.setState({ dataI18n: 'nin_residence' });
        localStorage.setItem('userType', 'Visitor');
        localStorage.setItem('serviceEntity', 'المركز السعودى للأعمال (الموظف الشامل)');

        let service = 'sbc-others';
        this.inWorkingHours(service);
        let entity_row = document.getElementById('entity_row') as HTMLInputElement;
        // let nin_row_1 = document.getElementById("nin_row_1") as HTMLInputElement;
        // let nin_row_2 = document.getElementById("nin_row_2") as HTMLInputElement;
        // let nin_row_3 = document.getElementById("nin_row_3") as HTMLInputElement;

        let cr_row_1 = document.getElementById('cr_row_1') as HTMLInputElement;
        let cr_row_2 = document.getElementById('cr_row_2') as HTMLInputElement;
        let cr_row_3 = document.getElementById('cr_row_3') as HTMLInputElement;
        if (entity_row && cr_row_1 && cr_row_2 && cr_row_3) {
            entity_row.style.display = 'none';
            cr_row_1.style.display = 'none';
            cr_row_2.style.display = 'none';
            cr_row_3.style.display = 'none';
        }
        // nin_row_1.style.display = '';
        // nin_row_2.style.display = '';
        // nin_row_3.style.display = '';
    };

    inWorkingHours = (service: string) => {
        let language = localStorage.getItem('lang');
        if (service == 'sbc-others') {
            this.setState({ visitors: true });
            this.setState({ investors: false });
        } else {
            this.setState({ investors: true });
            this.setState({ visitors: false });
        }
        if (localStorage.getItem('userType') == 'Investor') {
            this.setState({ investors: true });
        }
        let url = appconfig.azure_reports_url + '/api/customer-meeting/isWorkingHours/' + service;
        fetch(url)
            .then((response) => {
                return response.json();
            })
            .then((json) => {
                if (json.statues == true) {
                    this.setState({ workingHour: true });
                    this.setState({ workingHourMessage: false });
                } else {
                    this.setState({ workingHour: false });
                    this.setState({ workingHourMessage: true });


                    let serviceMeassageAR = document.getElementById('serviceMeassageAR') as HTMLElement;
                    let serviceMeassageEN = document.getElementById('serviceMeassageEN') as HTMLElement;
                    serviceMeassageAR.innerText = json.messge[1];
                    serviceMeassageEN.innerText = json.messge[0];
                    if (language == 'ar') {
                        serviceMeassageAR.classList.remove('d-none');
                        serviceMeassageAR.classList.add('d-block');
                    } else {
                        serviceMeassageEN.classList.remove('d-none');
                        serviceMeassageEN.classList.add('d-block');
                    }
                }

                setLanguage(language);
            });
    };

    serviceChange = () => {
        let service = document.getElementById('entity') as HTMLInputElement;
        this.setState({ selectedIndex: service.value });
        if (service.value !== "0") {
            this.inWorkingHours(service.value);
        }

    };

    updateStep = (investorData: any) => {
        this.setState({ showNafathBlock: false });
        this.setState({ investorDataFromNafath: investorData?.investor });
        this.setState({ nin: investorData.nin });
        this.setState({ fullname: investorData.name });
        let entity_row = document.getElementById('entity_row') as HTMLInputElement;
        let cr_row_1 = document.getElementById('cr_row_1') as HTMLInputElement;
        let cr_row_2 = document.getElementById('cr_row_2') as HTMLInputElement;
        let cr_row_3 = document.getElementById('cr_row_3') as HTMLInputElement;

        entity_row.style.display = '';
        cr_row_1.style.display = '';
        cr_row_2.style.display = '';
        cr_row_3.style.display = '';
    };

    render() {
        console.log(this.state.selectedLanguage, 'this.state.selectedLanguage')
        return (
            <div className="content-container" id="content-container">
                <div className="row">
                    {/* START RIGHT SECTION */}
                    <div className="col-md-5 Rside">
                        {/*<h1> Dasarati CI/CD pipleline</h1>*/}

                        <div className="row">
                            <div className="t2" data-i18n="our_branch">
                                {' '}
                                الاتصال المرئ(إطلاق تجريبي)
                            </div>
                            {/*<div className='t2' data-i18n="our_branch">  الاتصال المرئي <span className='t1' data-i18n="application_name">عندك</span></div>*/}
                        </div>
                        <div className="row">
                            <span data-i18n="message">
                                تتيح هذه الخدمة امكانية عقد <b>زيارة افتراضية </b>والتواصل مع موظف خدمة العملاء عبر الاتصال المرئى بكل
                                سهولة ويسر
                            </span>
                        </div>

                        {/* <video id="waitingVideo" autoPlay loop playsInline muted>
                            <source type="video/mp4" id="videoSource"></source>
                        </video> */}
                    </div>
                    {/* END RIGHT SECTION */}

                    <div className="col-md-7">
                        <div className="leftSide">
                            <div className="row">
                                <div className="t1" data-i18n="start_from_here">
                                    إبدأ من هنا
                                </div>
                            </div>

                            <div className="row">
                                <span className="mandatoryfield">*</span>
                                &nbsp;&nbsp;
                                <span className="label1" data-i18n="service_type">
                                    نوع الوثيقة{' '}
                                </span>
                            </div>

                            {/* START SERVICE TYPE SECTION */}
                            <div className="row">
                                <div className="col-md-7">
                                    <input
                                        type="radio"
                                        name="serviceType"
                                        value="sbc-others"
                                        onClick={() => {
                                            this.hideEntity();
                                        }}
                                    />
                                    <span className="" data-i18n="visitor">
                                        جواز السفر / الهوية الخليجية
                                    </span>
                                </div>
                                <div className="col-md-5">
                                    <input
                                        type="radio"
                                        name="serviceType"
                                        value=""
                                        onClick={() => {
                                            this.displayEntity();
                                        }}
                                    />
                                    <span className="" data-i18n="investor">
                                        الهوية الوطنية / الإقامة                     {' '}
                                    </span>
                                </div>
                            </div>
                            {/* END SERVICE TYPE SECTION */}

                            <div className="row">
                                <span
                                    id="serviceTypeValidationMsg"
                                    style={{ color: 'red', display: 'none', fontSize: '11px' }}
                                    data-i18n="required_field"
                                ></span>
                            </div>

                            {/* START LANGUAGE SECTION */}
                            <br />
                            {this.state.workingHour && (
                                <>
                                    <div className="row">
                                        <span className="mandatoryfield">*</span>
                                        &nbsp;&nbsp;
                                        <span className="label1" data-i18n="service_language">
                                            لغة التواصل
                                        </span>
                                    </div>

                                    <div className="row">
                                        <div className="col-md-7">
                                            <input type="radio" name="language" value="Arabic" checked={this.state.selectedLanguage == "Arabic"} onChange={this.handleChangeLanguage} />
                                            <span className="" data-i18n="arabic">
                                                العربية
                                            </span>
                                        </div>
                                        <div className="col-md-5">
                                            <input type="radio" name="language" value="English" checked={this.state.selectedLanguage == "English"} onChange={this.handleChangeLanguage} />
                                            <span className="" data-i18n="english">
                                                الانجليزية{' '}
                                            </span>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <span
                                            id="languageValidationMsg"
                                            style={{ color: 'red', display: 'none', fontSize: '11px' }}
                                            data-i18n="required_field"
                                        >
                                            {' '}
                                        </span>
                                    </div>
                                    <br />
                                </>
                            )}
                            {/* END LANGUAGE SECTION */}

                            {/* INVESTOR SECOND STEP : Start After Nafath Integration   */}
                            <>
                                {' '}

                                <div style={{ display: this.state.investors ? '' : 'none' }}>
                                    <div className="row" id="entity_row" style={{ display: 'none' }}>
                                        <span className="mandatoryfield">*</span>
                                        &nbsp;&nbsp;
                                        <span className="label1" data-i18n="entity">
                                            الجهة
                                        </span>
                                        &nbsp; &nbsp;
                                        <select
                                            className="form-control"
                                            value={this.state.selectedIndex}
                                            id="entity"
                                            onChange={this.serviceChange}
                                        >
                                            <option value="0" data-i18n="select">
                                                اختر
                                            </option>
                                            <option data-i18n="entity_option_1" value="sbc-moc">
                                                وزارة التجارة
                                            </option>
                                            <option data-i18n="entity_option_2" value="sbc-momrah">
                                                وزارة البلديات والإسكان
                                            </option>
                                            <option data-i18n="entity_option_3" value="sbc-hrsd">
                                                وزارة الموارد البشرية والتنمية الاجتماعية
                                            </option>
                                            <option data-i18n="entity_option_10" value="sbc-tourism">
                                                وزارة السياحة
                                            </option>
                                            <option data-i18n="entity_option_4" value="sbc-investment">
                                                وزارة الاستثمار
                                            </option>
                                            <option data-i18n="entity_option_5" value="sbc-gosi">
                                                المؤسسة العامة للتأمينات الاجتماعية
                                            </option>
                                            {/* <option data-i18n="entity_option_6" value="sbc-federation">
                          اتحاد الغرف السعودية
                        </option> */}
                                            <option data-i18n="entity_option_7" value="sbc-zatca">
                                                هيئة الزكاة والضريبة والجمارك
                                            </option>
                                            <option data-i18n="entity_option_8" value="sbc-moe">
                                                وزارة التعليم
                                            </option>
                                            <option data-i18n="entity_option_9" value="sbc-others">
                                                المركز السعودى للأعمال (الموظف الشامل)
                                            </option>
                                            <option data-i18n="entity_option_11" value="sbc-compatibility">
                                                الموائمة
                                            </option>
                                            {/* <option data-i18n="entity_option_12" value="sbc-verification">
                          التوثيق
                        </option> */}
                                        </select>
                                    </div>
                                    <div className="row">
                                        <span
                                            id="entityRequiredMsg"
                                            style={{ color: 'red', display: 'none', fontSize: '11px' }}
                                            data-i18n="required_field"
                                        >
                                            هذا الحقل مطلوب
                                        </span>
                                    </div>
                                    <br></br>
                                    {/*  */}
                                    <div className="row" id="cr_row_1" style={{ display: 'none' }}>
                                        <span className="mandatoryfield">*</span>
                                        &nbsp;&nbsp;
                                        <span className="label1" data-i18n="commercial_record">
                                            {' '}
                                            السجل التجاري{' '}
                                        </span>
                                    </div>
                                    <div className="row" id="cr_row_2" style={{ display: 'none' }}>
                                        <input
                                            className="form-control"
                                            maxLength={10}
                                            type="text"
                                            onChange={this.checkregistrationNumber}
                                            data-i18n="commercial_record_placeholder"
                                            value={this.state.commercialNumber}
                                            placeholder="السجل التجاري"
                                            name="commercial_record"
                                            id="commercial_record"
                                        />
                                    </div>
                                    <div className="row" id="cr_row_3" style={{ display: 'none' }}>
                                        <span
                                            id="crRequiredMsg"
                                            style={{ color: 'red', display: 'none', fontSize: '11px' }}
                                            data-i18n="required_field"
                                        >
                                            هذا الحقل مطلوب
                                        </span>
                                    </div>
                                </div>

                            </>
                            {/* INVESTOR SECOND STEP : End After Nafath Integration */}

                            {this.state.investorDataFromNafath && (this.state.investorDataFromNafath as any).name ? null : (
                                <Nafath setInvestorData={this.updateStep} showNafathBlock={this.state.showNafathBlock} />
                            )}

                            {this.state.workingHour && (
                                <>
                                    {/* Start NIN */}
                                    <>
                                        <br></br>
                                        <div className="row" id="nin_row_1">
                                            {/* {this.state.investors && <span className="mandatoryfield">*</span>} */}
                                            <span className="mandatoryfield">*</span>
                                            &nbsp;&nbsp;
                                            <span className="label1" data-i18n={this.state.dataI18n} >
                                                {' '}
                                                جواز السفر / الهوية الخليجية
                                            </span>
                                        </div>
                                        <div className="row" id="nin_row_2">
                                            <input
                                                className="form-control"
                                                maxLength={15}
                                                type="text"
                                                data-i18n="nin_residence_placeholder"
                                                onChange={this.checkNinOnlyDigits}
                                                value={this.state.nin}
                                                placeholder="جواز السفر / الهوية الخليجية"
                                                name="nin"
                                                id="nin"
                                                disabled={this.state.investors}
                                            />
                                        </div>
                                        <div className="row" id="nin_row_3">
                                            <span
                                                id="ninRequiredMsg"
                                                style={{ color: 'red', display: 'none', fontSize: '11px' }}
                                                data-i18n="required_field"
                                            >
                                                هذا الحقل مطلوب
                                            </span>
                                        </div>
                                        <br />
                                        <br />
                                    </>
                                    {/* End NIN */}
                                    {/* Start Name */}
                                    <>
                                        <div className="row">
                                            <span className="mandatoryfield">*</span> <br />
                                            &nbsp;&nbsp;
                                            <span className="label1" data-i18n="name">
                                                {' '}
                                                الاسم
                                            </span>
                                        </div>
                                        <div className="row">
                                            <input
                                                className="form-control"
                                                type="text"
                                                data-i18n="name_placeholder"
                                                placeholder="الاسم"
                                                name="fullname"
                                                id="fullname"
                                                onChange={this.onChangeFullName}
                                                value={this.state.fullname}
                                                disabled={this.state.investors}
                                            />
                                        </div>
                                        <div className="row">
                                            <span
                                                id="nameRequiredMsg"
                                                style={{ color: 'red', display: 'none', fontSize: '11px' }}
                                                data-i18n="required_field"
                                            >
                                                هذا الحقل مطلوب
                                            </span>
                                        </div>
                                        <br /> <br />
                                    </>
                                    {/* End Name */}
                                    {/* Start Phone */}
                                    <>
                                        <div className="row">
                                            <span className="mandatoryfield">*</span>
                                            &nbsp;&nbsp;
                                            <span className="label1" data-i18n="mobile">
                                                رقم الجوال{' '}
                                            </span>
                                        </div>
                                        <div className="row">
                                            <input
                                                className="form-control"
                                                value={this.state.mobile}
                                                style={{ fontFamily: 'tahoma !important' }}
                                                maxLength={10}
                                                type="text"
                                                data-i18n="mobile_placeholder"
                                                onChange={this.checkMobileOnlyDigits}
                                                placeholder="رقم الجوال"
                                                name="mobile"
                                                id="mobile"
                                            />
                                        </div>
                                        <div className="row">
                                            <span
                                                id="mobileRequiredMsg"
                                                style={{ color: 'red', display: 'none', fontSize: '11px' }}
                                                data-i18n="required_field"
                                            >
                                                هذا الحقل مطلوب
                                            </span>
                                        </div>
                                        <br />
                                        <br />
                                    </>
                                    {/* End Phone */}
                                    {/* Start Email */}
                                    <>
                                        <div className="row">
                                            {/*<span className="mandatoryfield">*</span>*/}
                                            &nbsp;&nbsp;
                                            <span className="label1" data-i18n="email">
                                                الإيميل
                                            </span>
                                        </div>
                                        <div className="row">
                                            <input
                                                className="form-control"
                                                style={{ fontFamily: 'tahoma !important' }}
                                                type="email"
                                                data-i18n="email_placeholder"
                                                onChange={this.checkEmail}
                                                placeholder="الإيميل"
                                                name="email"
                                                id="email"
                                            />
                                        </div>
                                        <div className="row">
                                            <span
                                                id="emailRequiredMsg"
                                                style={{ color: 'red', display: 'none', fontSize: '11px' }}
                                                data-i18n="validation_email"
                                            >
                                                الإيميل غير صحيح
                                            </span>
                                        </div>
                                        <br />
                                        <br />
                                    </>
                                    {/* End Email */}
                                    {/* Start Accept Policies */}
                                    <>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <span className="mandatoryfield">*</span>
                                                &nbsp;&nbsp;
                                                <input type="checkbox" name="securityConfirm" id="securityConfirm" value="confirmed" onChange={this.handelSecurity} />
                                                <span data-i18n="confirmation_text" id="confirmation_text">
                                                    {' '}
                                                    أتعهد انا مستخدم هذه الخدمة بعدم تسجيل هذه المكالمة وأن احافظ على سرية البيانات الواردة فيها
                                                    التزاماً بكافة الأحكام والقواعد الواردة في نظام مكافحة الجرائم المعلوماتية الصادر بالمرسوم
                                                    الملكي رقم م/17 وتاريخ 1428/03/08, وبجميع الأنظمة ذات الصلة{' '}
                                                </span>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <span
                                                id="securityVonfirmRequiredMsg"
                                                style={{ color: 'red', display: 'none', fontSize: '11px' }}
                                                data-i18n="confirmation_approval"
                                            >
                                                الرجاء الموافقه على الإقرار{' '}
                                            </span>
                                        </div>
                                        <br />
                                        <br />
                                        <div className="row">
                                            <div className="col-md-12">
                                                <span className="mandatoryfield">*</span>
                                                &nbsp;&nbsp;
                                                <input type="checkbox" name="recordConfirm" id="recordConfirm" value="confirmed" onChange={this.handelRecord} />
                                                <span data-i18n="record_text" id="record_text">
                                                    {' '}
                                                    أتعهد بالالتزام بالمظهر اللائق والزي الرسمي والمكان المناسب قبل وأثناء الاستفادة من الخدمة،
                                                    وفي حال مخالفة ذلك سيتم إنهاء الاتصال وتطبيق الأنظمة والتعليمات ذات الصلة{' '}
                                                </span>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <span
                                                id="recordConfirmRequiredMsg"
                                                style={{ color: 'red', display: 'none', fontSize: '11px' }}
                                                data-i18n="confirmation_approval"
                                            >
                                                الرجاء الموافقه على الإقرار{' '}
                                            </span>
                                        </div>
                                        <br />
                                        <br />
                                    </>
                                    {/* End Accept Policy */}

                                    {/* START OLD NEXT TO NAFATH MODAL BUTTON AND API ERROR MESSAGE */}
                                    <div className="row">
                                        <button className="btn btn-primary" id="submitbtn" onClick={this.openWelcomeScreen}>
                                            <span data-i18n="submit_btn">استمرار</span>
                                            &nbsp;&nbsp;
                                            <span
                                                id="submitButtonSpinner"
                                                style={{ display: 'none' }}
                                                className="spinner-border spinner-border-sm"
                                            ></span>
                                        </button>
                                        {/* End Open Next Screen */}

                                        {/* Error In Nafaz API */}
                                        {this.state.nafathErrorApi && (
                                            <span
                                                className="label1"
                                                data-i18n="nafathErrorApiMessg"
                                                style={{ display: 'block', color: 'red', width: '100%', marginTop: '10px' }}
                                            >
                                                {' '}
                                                يوجد خطأ يرجى طلب المساعدة{' '}
                                            </span>
                                        )}
                                        {/* End Error In Nafath API */}
                                    </div>
                                    {/* END OLD NEXT TO NAFATH MODAL BUTTON AND API ERROR MESSAGE */}
                                </>
                            )}

                            {/* START AFTER WORKING HOUR MESSAGE */}
                            <div style={{ display: this.state.workingHourMessage ? '' : 'none' }}>
                                <div className="row" >
                                    <span className="label1" data-i18n="workingHourMessageHeader">
                                        {' '}
                                        نأسف لعدم القدرة على خدمتكم في هذا الوقت{' '}
                                    </span>
                                </div>
                                <div className="row">
                                    <span id="serviceMeassageAR" className="d-none"></span>
                                    <span id="serviceMeassageEN" className="d-none"></span>
                                </div>
                            </div>
                            {/* END AFTER WORKING HOUR MESSAGE */}
                        </div>
                    </div>
                </div>
                {this.state.nafathOpen && (
                    <>
                        <NafathModal
                            isOpen={this.state.nafathOpen}
                            onClose={this.handleCloseNafathModal}
                            data={this.state.nafathObject}
                            urlAttributes={this.state.urlAttributes}
                        />
                    </>
                )}
            </div>
        );
    }
}
const mapStateToProps = (state: State, props: CommonProps) => ({
    serviceType: state.common.serviceType
});

const mapDispatchToProps = (dispatch: any, props: CommonProps) => ({
    setServiceType: (serviceType: string) => dispatch(setServiceType(serviceType)),
    setFullName: (fullName: string) => dispatch(setFullName(fullName)),
    setNin: (nin: string) => dispatch(setNin(nin)),
    setCommercialNumber: (commercialNumber: string) => dispatch(setCommercialNumber(commercialNumber)),
    setMobile: (mobile: string) => dispatch(setMobile(mobile)),
    setToken: (token: string) => dispatch(setToken(token))
});

const connector: any = connect(mapStateToProps, mapDispatchToProps);
export default connector(InputForm);
